export const LoginURL = 'login';
export const logoutURL = 'logout';
export const forgotPasswordURL = 'forgot-password';
export const resetPasswordURL = 'reset-password';
export const refreshURL = 'refresh-token';

export const downloadTrackerURL = 'download-tracker';
export const downloadSingleTrackerURL = 'single-tracker-download';

// Dashboard
export const CommonListURL = 'dashboard/common-list';
export const DashboardLeaveListURL = 'dashboard/leave-list';
export const celebrationListURL = 'dashboard/celebrations';
export const projectCardURL = 'dashboard/project';
export const taskCardURL = 'dashboard/task';
export const lateCardURL = 'dashboard/late';
export const earlyLeaveCardURL = 'dashboard/early_leave';

// Calender
export const calenderURL = 'calender';

// Role
export const roleInitURL = 'role/init';
export const roleListURL = 'role/list';
export const roleCreateURL = 'role/create';
export const roleUpdateURL = 'role/update';
export const roleDeleteURL = 'role/delete';

// PermissionGroup
export const permissionGroupListURL = 'permission-group/list';
export const permissionGroupCreateURL = 'permission-group/create';
export const permissionGroupUpdateURL = 'permission-group/update';
export const permissionGroupDeleteURL = 'permission-group/delete';

// User
export const UserAddressUpdateURL = 'user/update-address';
export const UserPassWordChangeURL = 'user/change-password';
export const UserProfileUpdateURL = 'user/update-profile';
export const UserProfilePictureUpdateURL = 'user/update-profile-picture';
export const UserProfilePictureDeleteURL = 'user/delete-profile-picture';
export const UserResumeUploadURL = 'user/upload-resume';
export const UserResumeDeleteURL = 'user/delete-resume';
export const UserLoginDataURL = 'user/login-log';
export const UserLogoutOtherDeviceURL = 'user/logout-other-device';

// Emergency Contact
export const EmergencyContactListURL = 'user-emergency-contact/list';
export const EmergencyContactCreateURL = 'user-emergency-contact/create';
export const EmergencyContactUpdateURL = 'user-emergency-contact/update';
export const EmergencyContactDeleteURL = 'user-emergency-contact/delete';

// Document
export const DocumentListURL = 'user-document/list';
export const DocumentCreateURL = 'user-document/create';
export const DocumentDeleteURL = 'user-document/delete';

// Employee
export const EmployeeInitURL = 'employee/init';
export const EmployeeListURL = 'employee/list';
export const EmployeeCreateURL = 'employee/create';
export const EmployeeUpdateURL = 'employee/update';
export const EmployeeDeleteURL = 'employee/delete';
export const EmployeeListCountURL = 'employee/list-counts';
export const EmployeeListExportURL = 'employee/export-list';
export const EmployeeTypeUpdateURL = 'employee/type-update';
export const TrackerSettingUpdateURL = 'employee/tracker-settings';
export const EmployeeSalaryUpdate = 'employee/salary-update';
export const EmployeePaySlipListURL = 'employee/payslip-list';
export const EmployeeCreateUpdateURL = 'employee/update-bank-details';

// Organization
export const organizationListURL = 'organization/list';
export const organizationCreateURL = 'organization/create';
export const organizationUpdateURL = 'organization/update';
export const organizationDeleteURL = 'organization/delete';

// Location
export const locationInitURL = 'location/init';
export const locationListURL = 'location/list';
export const locationCreateURL = 'location/create';
export const locationUpdateURL = 'location/update';
export const locationDeleteURL = 'location/delete';

// Department
export const departmentInitURL = 'department/init';
export const departmentListURL = 'department/list';
export const departmentCreateURL = 'department/create';
export const departmentUpdateURL = 'department/update';
export const departmentDeleteURL = 'department/delete';

// Department
export const designationInitURL = 'designation/init';
export const designationListURL = 'designation/list';
export const designationCreateURL = 'designation/create';
export const designationUpdateURL = 'designation/update';
export const designationDeleteURL = 'designation/delete';

// Announcement
export const announcementListURL = 'announcement/list';
export const announcementMonthlyListURL = 'announcement/monthly-list';
export const announcementCreateURL = 'announcement/create';
export const announcementUpdateURL = 'announcement/update';
export const announcementDeleteURL = 'announcement/delete';
export const announcementMarkedURL = 'announcement/mark-as-read';

// Leave
export const leaveInitURL = '/leave/init';
export const leaveListURL = '/leave/list';
export const getDaysListURL = '/leave/get-days';
export const todayLeaveListURL = '/leave/today-leaves';
export const leaveDetailsListURL = 'leave/leaves-details';
export const currentLeaveDetailsListURL = 'leave/current-leaves-details';
export const employeeLeaveDetailsListURL = '/leave/employee-leaves-details';
export const singleLeaveAddURL = 'leave/single-leave-create';
export const leaveCreateURL = '/leave/create';
export const leaveDeleteURL = '/leave/delete';
export const leaveCancelURL = '/leave/cancel';
export const leaveApproveURL = '/leave/new-approve';
export const multiLeaveApproveURL = '/leave/new-multi-approve';

// New Leave
export const checkLeaveBalanceURL = '/leave/check-balance';
export const getLeaveBalanceURL = '/leave/get-balance';
export const createNewLeaveURL = '/leave/new-create';

// Opening Balance
export const openingBalanceInitURL = '/leave/opening-balance/init';
export const openingBalanceGenerateURL = '/leave/opening-balance/generate';
export const openingBalanceListURL = '/leave/opening-balance/list';
export const openingBalanceCreateURL = '/leave/opening-balance/create';
export const openingBalanceDeleteURL = '/leave/opening-balance/delete';
export const openingBalanceUpdateURL = '/leave/opening-balance/update';

// User Leave Balance
export const leaveTypesListURL = 'leave-types/list';
export const leaveTypesCreateURL = 'leave-types/create';
export const leaveTypesUpdateURL = 'leave-types/update';
export const leaveTypesDeleteURL = 'leave-types/delete';

// User Leave Balance
export const leaveBalanceInitURL = '/user-leave-balance/init';
export const leaveBalanceListURL = '/user-leave-balance/list';
export const leaveBalanceReportListURL = '/leave/balance-report';
export const leaveBalanceReportURL = '/user-leave-balance/report';

// TimeSheet
export const timeSheetInitURL = 'time-sheet/init';
export const attendanceDataURL = 'time-sheet/attendance-data';
export const timeSheetNewCreateURL = 'time-sheet/in-event';
export const timeSheetNewUpdateURL = 'time-sheet/out-event';
export const EmployeeWorkStatusUpdateURL = 'time-sheet/wfh_or_os_update';
export const timeSheetInEventURL = 'time-sheet/new-in-event';
export const timeSheetOutEventURL = 'time-sheet/new-out-event';
export const dailyDataAddURL = 'time-sheet/add-daily-data';
export const dailyDataUpdateURL = 'time-sheet/update-daily-data';
export const dailyDataDeleteURL = 'time-sheet/delete-daily-data';

// Attendance Sheet
export const attendanceSheetInitialURL = 'attendance/initial';
export const attendanceSheetInitURL = 'attendance/init';
export const attendanceSheetListURL = 'attendance/list';
export const exportAttendanceSheetListURL = 'attendance/export';

// Monthly Time Sheet
export const MonthlyAttendanceListURL = 'time-sheet/monthly-attendance';
export const MonthlyAttendanceListExportURL = 'time-sheet/export-monthly-attendance';
export const AttendanceByIdAndDateURL = 'time-sheet/attendance-by-user-date';

// Employee Activity
export const EmployeeActivityURL = 'employee-activity/list';
export const EmployeeScreenShotDeleteURL = 'employee-activity/delete-screen-shot';

// Office Shift
export const officeShiftListURL = 'office-shift/list';
export const officeShiftCreateURL = 'office-shift/create';
export const officeShiftUpdateURL = 'office-shift/update';
export const officeShiftDeleteURL = 'office-shift/delete';

// Time Change Request
export const timeChangeRequestInitURL = 'employee-time-change-request/init';
export const timeSheetDataURL = 'employee-time-change-request/timeSheetData-on-date';
export const timeChangeRequestListURL = 'employee-time-change-request/list';
export const timeChangeRequestCreateURL = 'employee-time-change-request/create';
export const timeChangeRequestUpdateURL = 'employee-time-change-request/update';
export const timeChangeRequestDeleteURL = 'employee-time-change-request/delete';
export const timeChangeStatusUpdateURL = 'employee-time-change-request/status-update';

// Holiday
export const holidayListURL = 'holiday/list';
export const holidayCreateURL = 'holiday/create';
export const holidayUpdateURL = 'holiday/update';
export const holidayDeleteURL = 'holiday/delete';

// Ticket
export const ticketInitURL = 'ticket/init';
export const ticketListURL = 'ticket/list';
export const ticketCreateURL = 'ticket/create';
export const ticketUpdateURL = 'ticket/update';
export const ticketDeleteURL = 'ticket/delete';
export const ticketStatusUpdateURL = 'ticket/update-status';

// Project
export const projectInitURL = 'project/init';
export const employeeProjectURL = 'project/employee-project';
export const projectOverviewURL = 'project/overview';
export const projectListURL = 'project/list';
export const projectCreateURL = 'project/create';
export const projectUpdateURL = 'project/update';
export const projectDeleteURL = 'project/delete';

//Project-Clients
export const clientInitURL = 'project-clients/init';
export const clientListURL = 'project-clients/list';
export const clientUpdateURL = 'project-clients/update';
export const clientCreateURL = 'project-clients/create';
export const clientDeleteURL = 'project-clients/delete';
//Merge Project
export const mergeProjectInitURL = 'project/merge';

// Project Member
export const projectMemberInitURL = 'project-employee/init';
export const projectMemberListURL = 'project-employee/list';
export const projectMemberCreateURL = 'project-employee/create';
export const projectMemberUpdateURL = 'project-employee/update';
export const projectMemberDeleteURL = 'project-employee/delete';

// Project Status
export const projectStatusInitURL = 'project-status/init';
export const projectStatusListURL = 'project-status/list';
export const projectStatusCreateURL = 'project-status/create';
export const projectStatusUpdateURL = 'project-status/update';
export const projectStatusDeleteURL = 'project-status/delete';

// Project Task
export const projectTaskInitURL = 'project-task/init';
export const projectTaskListURL = 'project-task/list';
export const projectRelatedListURL = 'project-task/list-byid';
export const projectTaskCreateURL = 'project-task/create';
export const projectTaskUpdateURL = 'project-task/update';
export const projectTaskDeleteURL = 'project-task/delete';

// Task
export const TaskListURL = 'task-comment/task-list';
export const TaskLogURL = '/task/task-log';
export const TaskSelectionURL = '/task/task-select';
export const TaskCommentListURL = 'task-comment/list';
export const projectTaskCommentCreateURL = 'task-comment/create';
export const TaskCommentUpdateURL = 'task-comment/update';
export const TaskCommentDeleteURL = 'task-comment/delete';
export const ProjectTaskNewListURL = 'project-task/new-list';

// Task Activity
export const TaskActivityInitURL = 'employee-task-time/init';
export const TaskActivityCreateURL = 'employee-task-time/create';
export const TaskActivityUpdateURL = 'employee-task-time/update';

// Settings
export const LeaveSettingListURL = '/common-settings/setting-list';
export const LeaveSettingCreateUpdateURL = 'common-settings/update-setting';
export const OrganizationCreateUpdateURL = 'common-settings/organization-setting';

// Payroll
export const PayrollInitURL = '/payroll/init';

// Payroll Settings
export const PayrollGradeInitURL = 'payroll-grade/init';
export const PayrollGradeListURL = 'payroll-grade/list';
export const PayrollGradeCreateURL = 'payroll-grade/create';
export const PayrollGradeUpdateURL = 'payroll-grade/update';
export const PayrollGradeDeleteURL = 'payroll-grade/delete';

// Salary Benefits
export const SalaryBenefitInitURL = 'salary-benefit/init';
export const SalaryBenefitListURL = 'salary-benefit/list';
export const SalaryBenefitListOnOrgURL = 'salary-benefit/list-by-organization';
export const SalaryBenefitCreateURL = 'salary-benefit/create';
export const SalaryBenefitUpdateURL = 'salary-benefit/update';
export const SalaryBenefitDeleteURL = 'salary-benefit/delete';

// Grade Benefits
export const GradeBenefitInitURL = 'grade-benefit/init';
export const GradeBenefitListURL = 'grade-benefit/list';
export const GradeBenefitCreateURL = 'grade-benefit/create';
export const GradeBenefitUpdateURL = 'grade-benefit/update';
export const GradeBenefitDeleteURL = 'grade-benefit/delete';

// On Site Employee
export const OnSiteListURL = 'onsite-employee/list';
export const OnSiteCreateURL = 'onsite-employee/create';
export const OnSiteUpdateURL = 'onsite-employee/update';
export const OnSiteDeleteURL = 'onsite-employee/delete';

// Payroll
export const PayrollGenerateURL = 'payroll/generate';
export const PayrollListURL = 'payroll/list';
export const PayrollDeleteURL = 'payroll/delete';
export const PayrollViewURL = 'payroll/show';
export const PayrollEditURL = 'payroll/edit';
export const PayrollUpdateURL = 'payroll/update';

//Unproductive Url
export const UnproductiveUrlListURL = 'unproductive-url/list';
export const UnproductiveUrlGenerateURL = 'unproductive-url/create';
export const UnproductiveUrlUpdateURL = 'unproductive-url/update';
export const UnproductiveUrlDeleteURL = 'unproductive-url/delete';

//Days On Url
export const DaysOnInitURL = 'days-on/init';
export const DaysOnListURL = 'days-on/list';
export const DaysOnGenerateURL = 'days-on/create';
export const DaysOnUpdateURL = 'days-on/update';
export const DaysOnDeleteURL = 'days-on/delete';

// Burn Down Reports
export const BurnDownInitURL = 'reports/burn-down-init';
export const BurnDownURL = 'reports/burn-down';
export const BurnDownExportURL = 'reports/burn-down-export';

// Project Summery Reports
export const ProjectSummaryInitURL = 'reports/project-summary-init';
export const ProjectSummaryListURL = 'reports/project-summary';
export const ProjectSummaryExportURL = 'reports/project-summary-export';
