import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';
// import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
// import Customization from '../Customization';
import navigation from 'menu-items';
import { drawerWidth } from 'store/constant';
import { SET_MENU } from 'store/actions';

// assets
import { IconChevronRight } from '@tabler/icons';
import { useEffect } from 'react';

import CompanyFavicon from 'assets/images/companyFavicon.png';
import useAuth from 'hooks/useAuth';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    marginTop: '60px',
    padding: 15,
    // backgroundColor: null,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        marginRight: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -drawerWidth,
            // marginLeft: '-12% ',
            width: `calc(100% - ${drawerWidth}px)`
        }
        // matchDownMd &&
        // [theme.breakpoints.down('md')]: {
        //     // marginLeft: '20px',
        //     width: `calc(100% - ${drawerWidth}px)`
        //     // padding: '16px'
        // },
        // [theme.breakpoints.down('sm')]: {
        //     // marginLeft: '10px',
        //     width: `calc(100% - ${drawerWidth}px)`
        //     // padding: '16px'
        //     // marginRight: '10px'
        // }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        marginRight: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            // marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            // marginLeft: '10px'
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const { RefreshToken } = useAuth();
    const { mimic, SwitchBack, user } = useAuth();

    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };

    useEffect(() => {
        // Dynamically set the favicon
        const setFavicon = () => {
            const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
            link.type = 'image/x-icon';
            link.rel = 'shortcut icon';
            link.href = CompanyFavicon; // Replace with the actual path or URL
            document.head.appendChild(link);
        };

        setFavicon();
    }, []);

    const SwitchBackHandler = () => {
        SwitchBack();
    };

    useEffect(() => {
        const handleVisibilityChange = () => {
            !document.hidden && RefreshToken();
        };

        // const intervalId = setInterval(() => {
        //     !document.hidden && RefreshToken();
        // }, 600000); // 600000 milliseconds = 10 minutes

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            // clearInterval(intervalId);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={7}
                sx={{
                    // opacity: '91%',
                    height: '60px',
                    background: theme.palette.secondary.light,
                    transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                }}
            >
                <Toolbar>
                    <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
                </Toolbar>
            </AppBar>

            {/* drawer */}
            <Sidebar drawerOpen={!matchDownMd ? leftDrawerOpened : !leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />
            {/* <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} /> */}

            {/* main content */}
            <Main theme={theme} open={leftDrawerOpened}>
                {/* breadcrumb */}
                {mimic.isMimic && (
                    <Toolbar
                        padding="5px"
                        sx={{ backgroundColor: theme.palette.warning.main, justifyContent: 'center', borderRadius: '4px', color: 'white' }}
                    >
                        You have login as
                        <strong style={{ marginLeft: '5px' }}>
                            {user.first_name} {user.last_name}
                        </strong>
                        . Click
                        <strong
                            onClick={SwitchBackHandler}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    SwitchBackHandler();
                                }
                            }}
                            role="button"
                            tabIndex="0"
                            style={{ cursor: 'pointer', marginLeft: '5px', marginRight: '4px' }}
                        >
                            here
                        </strong>
                        to return to the actual login page.
                    </Toolbar>
                )}

                <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                <Outlet />
            </Main>
            {/* <Customization /> */}
        </Box>
    );
};

export default MainLayout;
